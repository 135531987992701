
<template>
	<div id="setting" style="padding-left: 10px;">  
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>

    <div style="width: 200px;">
      <v-select style="width: 200px" placeholder="시리즈 수정" label="label"
        :options="seriesArray" @input="setSeries"/>
    </div>
    <div class="flex flex-wrap">
      <div style="margin: 20px 0 0; width: 250px; background-color: white;">
        <vs-radio v-model="bannerLinkType" vs-value="ext">외부</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="search">검색</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="series">시리즈</vs-radio>
        <vs-radio v-model="bannerLinkType" vs-value="category">카테고리</vs-radio>
        <vs-input class="inputx" v-if="bannerLinkType == 'ext'" placeholder="외부링크 입력" v-model="bannerLink" @change="setBannerLink('ext')" />
        <vs-input class="inputx" v-if="bannerLinkType == 'search'" placeholder="검색단어 입력" v-model="searchText" @change="setBannerLink('search')" />
        <v-select class="inputx" v-if="bannerLinkType == 'series'" placeholder="시리즈 선택" @input="setBannerLink('series')" 
                  v-model="bannerSeries" label="name" :options="$store.state.series" :clearable="false" />
        <v-select class="inputx" v-if="bannerLinkType == 'category'" placeholder="카테고리 선택" @input="setBannerLink('category')" 
                  v-model="bannerCategory" label="name" :options="$store.state.categories1" :clearable="false" />
        <vs-textarea v-model="bannerTag" />
        <vs-upload ref="bannerUpload" automatic limit="1" accept="image/*" @on-success="successUpload" @on-error="errorUpload" @on-delete="deleteFile"
          action="/api/photo/bannerUpload" />
      </div>
      <div style="margin: 20px 0 0; width: 410px; background-color: white;">
        <vs-table ref="table" :data="monthlyReport">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <datepicker type="month" v-model="monthlyReportTarget" lang="kr" style="width: 200px;" />
          </div>
          <template slot="thead">
            <vs-th sort-key="type">분류</vs-th>
            <vs-th sort-key="count">제공건수</vs-th>
          </template>
          <template slot-scope="{data}">
            <tbody>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                <vs-td><div>{{ tr.type }}</div></vs-td>
                <vs-td><div>{{ tr.count }}</div></vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
    <!-- <vs-select
      :success="seriesVar.code != ''"
      :warning="seriesVar.code == ''"
      placeholder="시리즈 수정"
      label=""
      v-model="seriesVar.code"
      @change="setSeries"
      >
      <vs-select-item :key="index" :value="item.code" :text="item.name + ' | ' + item.on_use" v-for="(item,index) in seriesArray"/>
    </vs-select> -->

    
    <!-- Start: 시리즈 수정 팝업 -->
    <div id="div-with-loading" class="news-detail-form vs-con-loading__container" style ="width: 690px;">
      <vs-popup ref="popup-newsDetail" classContent="popup-newsDetail" title="시리즈 수정" :active.sync="seriesEditModalActive">
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>시리즈 이름: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="이름 입력" v-model="seriesVar.name"/>
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>시리즈 설명: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" placeholder="설명 입력" v-model="seriesVar.description"/>
          </div>
        </div>
        <!-- <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>해당 카테고리: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">       
            <v-select style="width: 150px" placeholder="사용 여부" label="name" v-model="category1"
              :options="category1Array" :clearable="false"/>   
          </div>
        </div> -->
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-1/3 w-full">
          <span>시리즈 사용여부: </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">       
            <v-select style="width: 150px" placeholder="사용 여부" label="text" v-model="state"
              :options="seriesUseOption" :clearable="false"/>   
          </div>
        </div>
        <div class="vx-row" style="margin: 10px">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="sendSeries()">{{buttonText}}</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="seriesEditModalActive=false"
            >취소</vs-button>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 시리즈 수정 팝업 -->
	</div>
</template>

<script>
import vSelect from 'vue-select';
// import moment from 'moment';
export default {
  components: {
    'v-select': vSelect,
  },
  data(){
    return {
      seriesEditModalActive: false,
      seriesVar: {
        code: '',
        name: '',
        description: '',
        category1: '0',
        banner: '',
        state: null,
      },
      category1: {},
      state: null,
      seriesArray: [],
      category1Array: [],
      seriesUseOption: [
        { text: "사용", value: "1" },
        { text: "숨김", value: "0" }
      ],
      buttonText: "수정",
      bannerTag: "",
      searchText: "",
      bannerSeries: null,
      bannerCategory: null,
      bannerLink: "",
      bannerLinkType: "ext",
      monthlyReport: [],
      monthlyReportTarget: null,
    }
  },
  beforeRouteLeave (to, from, next) {
    this.seriesEditModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  watch:{
    bannerLinkType: function() {
      this.clearLink()
    },
    monthlyReportTarget: function() {
      console.log("target: ", this.monthlyReportTarget);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/setting/getMonthlyReport', {targetMonth: this.monthlyReportTarget})
        .then(function (response) {
          console.log("report response:", response);
          let tmpData = response.data[0];
          let tmpData2 = [];
          for(const key of Object.keys(tmpData)){
            tmpData2.push({type: key, count: tmpData[key]})
          }
          thisIns.monthlyReport = tmpData2;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        });
    }
  },
  computed:{
    validName(){
      return (this.valMultipe.value1.length > 0 && this.valMultipe.value2.length > 0)
    }
  },
  methods:{
    clearLink(){
      this.searchText = "";
      this.bannerSeries = null;
      this.bannerCategory2 = null;
      this.bannerLink = "";
    },
    successUpload(evnet){
      const cdnPath = "https://cdnimage.ebn.co.kr/ban/"
      // console.log(evnet.target.response)
      this.bannerTag = `<a href="" target="_blank"><img src="${cdnPath + evnet.target.response}" style="max-width: 100%"></a>`
      if(this.bannerLink){ this.bannerTag = this.bannerTag.replace(/href="([^"]*)"/, `href="${this.bannerLink}"`) }
      this.$vs.notify({color:'success',title:'Upload Success',text:'배너 이미지 업로드 성공'})
    },
    deleteFile(){
      this.bannerTag = "";
    },
    errorUpload(){
      // console.log(this.$refs.bannerUpload)
      this.$refs.bannerUpload.filesx = [];
      this.$refs.bannerUpload.srcs = [];
    },
    setBannerLink(type){
      if(type == 'search'){
        this.bannerLink = `/search?shwords=` + encodeURIComponent(this.searchText)
      }
      else if(type == 'series'){
        this.bannerLink = `/newsList?series=` + this.bannerSeries.code
      }
      else if(type == 'category'){
        this.bannerLink = `/newsList?category1=` + this.bannerCategory.code_num
      }
      if(this.bannerTag){ this.bannerTag = this.bannerTag.replace(/href="([^"]*)"/, `href="${this.bannerLink}"`) }
      // console.log(this.bannerTag)
    },
    setSeries(e){
      console.log("event: ", e);
      if(e != null){
        if(e.code != 'new'){
          this.buttonText = "수정";
          this.seriesVar = JSON.parse(JSON.stringify(this.seriesArray.find(data => data.code == e.code)));
          this.state = this.seriesUseOption.find(data => data.value == this.seriesVar.state);
          this.category1 = this.category1Array.find(data => data.code == e.category1);
        }else{
          // this.seriesVar.name = '';
          // this.seriesVar.code = '';
          // this.seriesVar.description = '';
          this.seriesVar = JSON.parse(JSON.stringify(this.seriesArray.find(data => data.code == e.code)));
          this.category1 = this.category1Array.find(data => data.code == 0);
          this.state = this.seriesUseOption.find(data => data.value == 1);
          this.buttonText = "생성";
        }
        this.seriesEditModalActive=true;
      }
    },
    sendSeries(){
      if(this.state == null){
        alert('사용 여부를 선택하세요!');
        return;
      }
      this.seriesVar.state = this.state.value;
      // this.seriesVar.category1 = this.category1.code;
      console.log(this.state.value, this.seriesVar.state);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/setting/series', this.seriesVar)
        .then(function () {
          thisIns.$vs.notify({
            title:'성공',
            text: '시리즈 수정 완료',
            color:'primary',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          // thisIns.$store.commit('SEREIESEDIT', thisIns.seriesArray.filter(series => series.on_use == 1))
          thisIns.created();
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.seriesEditModalActive = false;
          thisIns.$vs.loading.close();  
        });
    },
    created() {
      // this.seriesArray = this.$store.state.series;
      let targetTime = new Date();
      targetTime.setMilliseconds(0);
      targetTime.setSeconds(0);
      targetTime.setMinutes(0);
      targetTime.setHours(0);
      targetTime.setDate(1);
      this.monthlyReportTarget = targetTime;
      this.seriesArray = [{code: "new", name: "시리즈 추가", state: ''}];
      this.seriesVar.name = '';
      this.seriesVar.code = '';
      this.seriesVar.description = '';
      this.seriesVar.state = this.seriesUseOption.find(data => data.value == 1);
      this.$vs.loading({
        scale: 1.5
      });
      const thisIns = this;
      this.$http.post('/api/getVarList')
        .then(function (res) {
          console.log("get data: ", res.data);
          thisIns.category1Array = [{code: 0, name: '전체'}].concat(res.data[0]);
          thisIns.seriesArray = thisIns.seriesArray.concat(res.data[2]);
          for(let series of thisIns.seriesArray){
            series.label = series.name + ' - ' + series.state
          }
          thisIns.$store.dispatch('setSeriesState', {'seriesArray': thisIns.seriesArray.filter(series => series.state == 1)});
          thisIns.$vs.loading.close();  
        })
        .catch(function (error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
          thisIns.$vs.loading.close();  
        });
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {
    this.created();
  },
}
</script>